import React from "react"
import Layout from "../components/layout"
import PostCard from "../components/card"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import ScrollAnimation from "react-animate-on-scroll"
import HomepageFooter from "../components/footers/homepage_footer"
import {
  CWrapper,
  LatestCol,
  LatestRow,
  LatestContainer,
  Title,
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Arrow,
  BooksLogo,
  TheLatestContent,
  TheLatest,
  Scroller,
  Link,
  HomepageContent,
  HomepageWrapper,
} from "../styles/homepage/style"

const NavigationSlider = withAutoplay(AwesomeSlider)

const Slider = () => {
  return (
    <NavigationSlider
      className="awesome-slider"
      play={true}
      bullets={false}
      cancelOnInteraction={false}
      interval={5000}
      media={[
        {
          slug: "page-one",
          className: "page-one",
          children: Page1,
        },
        {
          slug: "page-two",
          className: "page-two",
          children: Page2,
        },
        {
          slug: "page-three",
          className: "page-three",
          children: Page3,
        },
        {
          slug: "page-four",
          className: "page-four",
          children: Page4,
        },
        {
          slug: "page-five",
          className: "page-five",
          children: Page5,
        },
        {
          slug: "page-six",
          className: "page-six",
          children: Page6,
        },
      ]}
    />
  )
}

const Homepage = ({ data }) => {
  const posts = data.allWordpressPost.edges.map(({ node }) => {
    const {
      slug,
      featured_media: { source_url },
      title,
      excerpt,
      categories,
    } = node
    return (
      <LatestCol md={6} lg={4} key={slug}>
        <Link to={node.slug} key={slug}>
          <ScrollAnimation animateIn={"fadeIn"} duration={2}>
            <PostCard
              id={slug}
              src={source_url}
              title={title}
              description={excerpt}
              category={categories.map(c => c.name).join("")}
              color={"black"}
            />
          </ScrollAnimation>
        </Link>
      </LatestCol>
    )
  })

  const latestPressClippingData = {
    slug: "/press-clipping",
    source_url: "/latest_new_article.png",
    title: "350 banks, 350.000 branches.",
    category: "BOOK REVIEW BY DOUGLAS BLAKEY",
    excerpt:
      "Allen founded Allen International in 1992. He grew the firm to be the world leader in financial services for branding and retail design. In nearly 30 years, he worked with around 350 banks, with his design projects responsible for over 350,000 branches.",
  }

  const latestPostData = {
    slug: "/latest-publication",
    source_url: "/BOOK MOCK UP.jpg",
    title: "Latest Publication",
    category: "A TOTAL BUNCH OF BANKERS",
    excerpt:
      "A retrospective by Michael Allen.  A guide to retail bank design and creativity for financial service companies, improving the customer experience and their brand image [...]",
  }

  const latestPost = (
    <LatestCol md={6} lg={4} key={latestPostData.slug}>
      <Link to={latestPostData.slug} key={latestPostData.slug}>
        <ScrollAnimation animateIn={"fadeIn"} duration={2}>
          <PostCard
            id={latestPostData.slug}
            src={latestPostData.source_url}
            title={latestPostData.title}
            description={latestPostData.excerpt}
            category={latestPostData.category}
            color={"black"}
          />
        </ScrollAnimation>
      </Link>
    </LatestCol>
  )

  const latestPressClipping = (
    <LatestCol md={6} lg={4} key={latestPressClippingData.slug}>
      <Link to={latestPressClippingData.slug} key={latestPressClippingData.slug}>
        <ScrollAnimation animateIn={"fadeIn"} duration={2}>
          <PostCard
            id={latestPressClippingData.slug}
            src={latestPressClippingData.source_url}
            title={latestPressClippingData.title}
            description={latestPressClippingData.excerpt}
            category={latestPressClippingData.category}
            color={"black"}
          />
        </ScrollAnimation>
      </Link>
    </LatestCol>
  )

  posts.push(latestPost)
  posts.push(latestPressClipping)

  return (
    <Layout footer={HomepageFooter} contentWrapper={CWrapper}>
      <HomepageWrapper>
        <HomepageContent>
          <Slider />
          <Scroller>
            <AnchorLink to="/#latest">
              <Arrow></Arrow>
            </AnchorLink>
          </Scroller>
        </HomepageContent>
      </HomepageWrapper>
      <TheLatest id="latest">
        <TheLatestContent>
          <Title>The Latest</Title>
          <LatestContainer fluid>
            <LatestRow>{posts}</LatestRow>
          </LatestContainer>
        </TheLatestContent>
      </TheLatest>
    </Layout>
  )
}

export default Homepage


export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }) {
      edges {
        node {
          content
          slug
          title
          id
          date
          excerpt
          categories {
            name
          }
          featured_media {
            source_url
          }
        }
      }
    }
  }
`

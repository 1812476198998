import styled, { keyframes } from "styled-components"
import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { Container, Row, Col } from "styled-bootstrap-grid"
import "react-awesome-slider/dist/styles.css"
import "animate.css/animate.min.css"
import "./index.css"

const Link = styled(GatsbyLink)`
  &:link,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const HomepageWrapper = styled.div`
  min-height: 100vh;
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const HomepageContent = styled.div`
  min-height: 100vh;
`

const Scroller = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: ${({
    theme: {
      scroller: { desktop },
    },
  }) => desktop};
  background-color: ${({
    theme: {
      palette: { pink },
    },
  }) => pink};
  z-index: 3;
`

const Paragraph = styled.p`
  color: white;
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w300};
  font-size: ${({
    theme: {
      font: { sizes },
    },
    first,
    big,
  }) => (first || big ? sizes.s50 : sizes.s42)};
  line-height: 1.2;
  position: relative;

  // @media only screen and (min-width: 1025px) {
  //   &:before {
  //     content: ${({ bulletless }) => !bulletless && "'•'"};
  //     position: absolute;
  //     top: 0;
  //     left: -10px;
  //   }
  // }

  margin-bottom: ${({ theme: { spacing } }) => spacing.s50};
  text-align: center;

  @media only screen and (min-width: 576px) {
    line-height: 1.3;
  }

  @media only screen and (min-width: 768px) {
    max-width: 55vw;
  }

  @media only screen and (min-width: 1025px) {
    text-align: left;
  }

  @media only screen and (min-width: 768px) {
    max-width: ${({ first, oneRow }) =>
      first ? "30vw" : oneRow ? "75vw" : "55vw"};
  }
`

const ParagraphPage6 = styled.p`
  color: white;
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w300};
  font-size: ${({
    theme: {
      font: { sizes },
    },
    first,
    big,
  }) => (first || big ? sizes.s50 : sizes.s42)};
  line-height: 1.2;
  position: relative;

  @media only screen and (min-width: 1025px) {
    &:before {
      content: ${({ bulletless }) => !bulletless && "'•'"};
      position: absolute;
      top: 0;
      left: -10px;
    }
  }

  margin-bottom: ${({ theme: { spacing } }) => spacing.s50};
  text-align: center;

  @media only screen and (min-width: 576px) {
    line-height: 1.3;
  }

  /* @media only screen and (min-width: 768px) {
    max-width: 55vw;
  } */

  @media only screen and (min-width: 1025px) {
    text-align: left;
  }

  /* @media only screen and (min-width: 768px) {
    max-width: ${({ first, oneRow }) =>
      first ? "30vw" : oneRow ? "75vw" : "55vw"};
  } */
`

const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  @media only screen and (min-width: 1025px) {
    justify-content: ${({ align }) => align || "center"};
    flex-direction: row;
  }
`

const TheLatest = styled.div`
  background-color: white;
  margin-top: 100vh;
  position: relative;
  z-index: 10;
`

const TheLatestContent = styled.div`
  padding-top: 5rem;
  padding-bottom: 50rem;

  @media only screen and (min-width: 576px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }
`

const ArrowAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  60% {
    -moz-transform: translateY(-15px);
    transform: translateY(-15px);
  }
`

const Arrow = styled.button`
  /*Reset's every elements apperance*/
  background: none repeat scroll 0 0 transparent;
  border: medium none;
  border-spacing: 0;
  color: #26589f;
  font-family: "PT Sans Narrow", sans-serif;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.default};
  font-weight: normal;
  line-height: 1.42rem;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  cursor: pointer;
  width: 35px;
  height: 19px;
  outline: none;

  &:focus {
    outline: none;
  }
  background-image: url(/arrow_down.png);
  background-size: cover;
  animation: ${ArrowAnimation} 3s infinite;
`

const PageLogo = styled.img`
  max-width: 200px;
  margin-bottom: ${({ theme: { spacing } }) => spacing.s40};
  left: 7rem;

  @media only screen and (min-width: 1025px) {
    position: absolute;
    bottom: ${({
      theme: {
        scroller: { desktop },
      },
    }) => desktop};
    margin-bottom: 0;
    min-width: 500px;
  }
  animation-name: ${fadeIn} !important;
  animation-timing-function: ease-in !important;
  animation-duration: 1.5s !important;
  animation-fill-mode: forwards !important;
  transition: all 1.5s;

  @media only screen and (min-width: 1900px) {
    min-width: 690px;
  }
`

const BooksLogo = styled.img`
  max-width: 200px;
  margin-bottom: ${({ theme: { spacing } }) => spacing.s40};
  left: 0rem;

  @media only screen and (min-width: 1025px) {
    /* position: absolute; */
    bottom: ${({
      theme: {
        scroller: { desktop },
      },
    }) => desktop};
    margin-bottom: 0;
    min-width: 600px;
    left: 15rem;
  }
  animation-name: ${fadeIn} !important;
  animation-timing-function: ease-in !important;
  animation-duration: 1.5s !important;
  animation-fill-mode: forwards !important;
  transition: all 1.5s;

  @media only screen and (min-width: 1900px) {
    min-width: 400px;
    left: 10rem;
  }
`

const PageWrapper = styled.div`
  padding: 0 ${({ theme: { spacing } }) => spacing.s30};
  animation-name: ${fadeIn} !important;
  animation-timing-function: ease-in !important;
  animation-duration: 1.5s !important;
  animation-fill-mode: forwards !important;
  transition: all 1.5s;

  @media only screen and (min-width: 1300px) {
    margin-right: ${({ first }) => first && "13rem"};
  }
`

const Page1 = (
  <Page align="flex-end">
    <PageLogo src="/logo.png" />
    <PageWrapper first>
      <Paragraph first bulletless>
        Global Strategic Design Consultants in Digital and Physical
        Transformation
      </Paragraph>
    </PageWrapper>
  </Page>
)

const Page2 = (
  <Page>
    <PageWrapper>
      <Paragraph bulletless big oneRow>
        New Virtual Digital Company For The Post-Coronavirus World
      </Paragraph>
    </PageWrapper>
  </Page>
)

const Page3 = (
  <Page>
    <PageWrapper>
      <Paragraph>
        We create exciting customer experiences through digital and branch
        concepts
      </Paragraph>
      <Paragraph>
        We optimize and connect digital and physical customer journeys
      </Paragraph>
      <Paragraph>
      We specialize in maximizing payback on branch transformation programs
      through a Kit of Parts
      </Paragraph>
    </PageWrapper>
  </Page>
)

const Page4 = (
  <Page>
    <PageWrapper>
      <Paragraph>
        We create robust brand strategies supported by detailed DNA analysis
      </Paragraph>
      <Paragraph>
        We develop multi-sensory experiences that enhance brand advocacy
      </Paragraph>
      <Paragraph>
        We train employees to act and think like retailers and those in the
        hospitality sector
      </Paragraph>
      <Paragraph>
        We create multiple formats that drives successful digital
        implementation
      </Paragraph>
    </PageWrapper>
  </Page>
)

const addLineBreaks = string =>
  string.split("\n\r").map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ))

const Page5 = (
  <Page>
    <PageWrapper>
      <Paragraph bulletless big oneRow>
        {addLineBreaks(
          "Experience with more than 350 banks \n\r and 300,000 Implemented Branches."
        )}
      </Paragraph>
    </PageWrapper>
  </Page>
)

const Page6 = (
  <Page align="flex-end">
    <BooksLogo src="/books.svg" />
    <PageWrapper first>
      <Paragraph bulletless big oneRow>
        Ultimate Guide To Branch Transformation.
      </Paragraph>
      <ParagraphPage6 bulletless>
        {addLineBreaks("Includes 18 case studies, with amusing")}
        {addLineBreaks("anecdotes and cartoons from experience")}
        {addLineBreaks("over 30 years in the design of retail bank")}
        {addLineBreaks("branches around the world.")}
      </ParagraphPage6>
      <ParagraphPage6>
        <a href="/latest-publication">
          <img src="/order_icon.png" />
        </a>
      </ParagraphPage6>
      <ParagraphPage6>Click to order your copy.</ParagraphPage6>
    </PageWrapper>
  </Page>
)

const Title = styled.h1`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  padding-left: 30px;
`

const LatestContainer = styled(Container)`
  padding: 0 40px;
`

const LatestRow = styled(Row)`
  margin: 0 -40px;
`

const LatestCol = styled(Col)`
  padding: 0 40px;
`

const ContentWrapper = styled.div`
  padding-bottom: 0;
`

const CWrapper = ({ children }) => <ContentWrapper children={children} />

export {
  CWrapper,
  LatestCol,
  ContentWrapper,
  LatestRow,
  LatestContainer,
  Title,
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  BooksLogo,
  Arrow,
  TheLatestContent,
  TheLatest,
  Scroller,
  Link,
  HomepageContent,
  HomepageWrapper,
}
